import React from "react";
import "./CreateReportModal.scss";
import {
  Modal,
  Form,
  Typography,
  Input,
  Select,
  Divider,
  Checkbox,
  DatePicker,
} from "antd";
import useCreateReportModal from "../../hooks/useCreateReportModal";
import classNames from "classnames";
import Utils from "../../utils";
const { RangePicker } = DatePicker;

export default function CreateReportModal({
  isOpened,
  title,
  onOk,
  onCancel,
  report,
}) {
  const {
    token,
    formRef,
    drivers,
    months,
    years,
    categoryLoading,
    categorys,
    isExactDateChecked,
    setIsExactChecked,
    isYearChecked,
    setIsYearChecked,
    isMonthChecked,
    setisMonthChecked,
    dispatch,
    loading,
    error,
    setSelectedYear,
    resetGenericReportError,
    formData,
    isMobile,
    possibleLocations,
    isByMetrc,
    setIsByMetrc,
  } = useCreateReportModal(isOpened, onCancel, report);
  const currentDate = new Date();
  const currentMonthIndex = currentDate?.getMonth();

  return (
    <Modal
      className="createReportModal-cont"
      open={isOpened}
      title={title}
      centered={true}
      okText="Save"
      closeIcon={false}
      onOk={() => {
        formRef?.current?.submit();
      }}
      onCancel={() => {
        onCancel();
        formRef?.current?.resetFields();
        setIsYearChecked(true);
        setisMonthChecked(true);
        setIsExactChecked(false);
        setSelectedYear(null);
        setIsByMetrc(false);
        if (error) {
          dispatch(resetGenericReportError());
        }
      }}
      cancelButtonProps={{
        className: "createReportModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        className: "createReportModal-cont__btn",
        htmlType: "submit",
        type: "primary",
        key: "submit",
        loading: loading && isOpened,
        disabled: error ? Object.keys(error)?.length !== 0 : false,
      }}
      style={{
        borderTop: `2px solid ${token?.Button?.colorPrimary}`,
      }}>
      <Form
        className="createReportModal-cont__form"
        ref={formRef}
        layout="vertical"
        onFinish={(values) => {
          const modifiedValues = {
            ...values,
            // Add or modify fields as needed
          };
          if (!isExactDateChecked) {
            const currentDate = new Date();
            onOk({
              name: modifiedValues?.name ? modifiedValues?.name : null,
              drivers: modifiedValues?.drivers?.length
                ? modifiedValues?.drivers
                : [],
              categories: modifiedValues?.categories?.length
                ? modifiedValues?.categories
                : [],
              report_type: modifiedValues?.report_type
                ? modifiedValues?.report_type
                : null,
              locations: modifiedValues?.locations?.length
                ? modifiedValues?.locations
                : [],
              start_date: Utils?.getFormattedDateTime(`${
                modifiedValues?.year && isYearChecked
                  ? modifiedValues?.year
                  : currentDate?.getFullYear()
              }
              ${
                modifiedValues?.month && isMonthChecked
                  ? modifiedValues?.month
                  : currentDate?.getMonth() + 1
              }`),
              end_date: Utils?.getFormattedDateTime(new Date()?.toString()),
              is_metrc_order: isByMetrc,
            });
          } else {
            if (modifiedValues?.exact_date[1]) {
              onOk({
                name: modifiedValues?.name ? modifiedValues?.name : null,
                drivers: modifiedValues?.drivers?.length
                  ? modifiedValues?.drivers
                  : [],
                categories: modifiedValues?.categories?.length
                  ? modifiedValues?.categories
                  : [],
                report_type: modifiedValues?.report_type
                  ? modifiedValues?.report_type
                  : null,
                locations: modifiedValues?.locations?.length
                  ? modifiedValues?.locations
                  : [],
                start_date: Utils?.getFormattedDateTime(
                  modifiedValues?.exact_date[0]
                ),
                end_date: Utils?.getFormattedDateTime(
                  modifiedValues?.exact_date[1]
                ),
                is_metrc_order: isByMetrc,
              });
            } else {
              onOk({
                name: modifiedValues?.name ? modifiedValues?.name : null,
                drivers: modifiedValues?.drivers?.length
                  ? modifiedValues?.drivers
                  : [],
                categories: modifiedValues?.categories?.length
                  ? modifiedValues?.categories
                  : [],
                report_type: modifiedValues?.report_type
                  ? modifiedValues?.report_type
                  : null,
                locations: modifiedValues?.locations?.length
                  ? modifiedValues?.locations
                  : [],
                start_date: Utils?.getFormattedDateTime(
                  modifiedValues?.exact_date[0]
                ),
                is_metrc_order: isByMetrc,
              });
            }
          }
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <div className="createReportModal-cont__form__formItemWrapper">
          <Form.Item
            className="createReportModal-cont__form__formItemWrapper__formItem"
            name="name"
            rules={[
              {
                required: true,
                message: "Please enter report name",
              },
            ]}
            label={
              <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                Report Name
              </Typography.Text>
            }
            colon={false}>
            <Input
              type="text"
              placeholder="Report Name"
              onChange={() => {
                if (error && error["name"]) {
                  dispatch(resetGenericReportError("name"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createReportModal-cont__form__formItemWrapper__formItem"
            name="categories"
            style={{
              marginTop: "5px",
            }}
            label={
              <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                Category
              </Typography.Text>
            }
            colon={false}>
            <Select
              placeholder="Category"
              mode="multiple"
              options={categorys?.map((el) => ({
                label: el?.name,
                value: el?.id,
              }))}
              showSearch
              allowClear
              filterOption={Utils?.filterOption}
              loading={categoryLoading}
              onChange={() => {
                if (error && error["categories"]) {
                  dispatch(resetGenericReportError("categories"));
                  formRef.current.setFields([
                    {
                      name: "categories",
                      errors: [], // Clear the error message for the date_range field
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createReportModal-cont__form__formItemWrapper">
          <Form.Item
            className="createReportModal-cont__form__formItemWrapper__formItem"
            name="locations"
            label={
              <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                Region
              </Typography.Text>
            }
            colon={false}>
            <Select
              showSearch
              allowClear
              mode="multiple"
              options={possibleLocations?.map((el) => ({
                label: el?.name,
                value: el?.id,
              }))}
              filterOption={Utils?.filterOption}
              placeholder="Region"
              onChange={() => {
                if (error && error["locations"]) {
                  dispatch(resetGenericReportError("locations"));
                  formRef.current.setFields([
                    {
                      name: "locations",
                      errors: [], // Clear the error message for the date_range field
                    },
                  ]);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="createReportModal-cont__form__formItemWrapper__formItem"
            name="drivers"
            label={
              <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                Drivers
              </Typography.Text>
            }
            colon={false}>
            <Select
              showSearch
              allowClear
              mode="multiple"
              options={drivers?.map((el) => ({
                label: `${el?.first_name} ${el?.last_name}`,
                value: el?.id,
              }))}
              placeholder="Drivers"
              filterOption={Utils?.filterOption}
              onChange={() => {
                if (error && error["drivers"]) {
                  dispatch(resetGenericReportError("drivers"));
                }
              }}
            />
          </Form.Item>
        </div>
        <Divider className="createReportModal-cont__form__divider">
          <Typography.Text className="createReportModal-cont__form__divider__text">
            Date Filter
          </Typography.Text>
        </Divider>
        <div className="createReportModal-cont__form__formItemWrapperChecbox">
          <Checkbox
            checked={isYearChecked}
            onChange={() => {
              if (!isYearChecked === false && !isMonthChecked) {
                setIsExactChecked(true);
                setIsYearChecked(!isYearChecked);
                if (error) {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                    }
                  }
                }
              } else {
                setIsYearChecked(!isYearChecked);
                setIsExactChecked(false);
                // if (formRef?.current?.getFieldValue("month")) {
                //   formRef?.current?.setFieldValue("month", undefined);
                // }
                if (error) {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                      formRef?.current?.setFields([
                        {
                          name: "month", // Field name you want to set the error for
                          errors: [], // Assuming your error has a 'message' field
                        },
                      ]);
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      formRef?.current?.setFields([
                        {
                          name: "month", // Field name you want to set the error for
                          errors: [], // Assuming your error has a 'message' field
                        },
                      ]);
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                      formRef?.current?.setFields([
                        {
                          name: "month", // Field name you want to set the error for
                          errors: [], // Assuming your error has a 'message' field
                        },
                      ]);
                    }
                  }
                }
              }
            }}>
            By Years
          </Checkbox>
          <Checkbox
            checked={isMonthChecked}
            onChange={() => {
              if (!isYearChecked && !isMonthChecked === false) {
                setIsExactChecked(true);
                setisMonthChecked(!isMonthChecked);
                if (error) {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                    }
                  }
                }
              } else {
                setisMonthChecked(!isMonthChecked);
                setIsExactChecked(false);
                if (error) {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                      formRef?.current?.setFields([
                        {
                          name: "year", // Field name you want to set the error for
                          errors: [], // Assuming your error has a 'message' field
                        },
                      ]);
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      formRef?.current?.setFields([
                        {
                          name: "year", // Field name you want to set the error for
                          errors: [], // Assuming your error has a 'message' field
                        },
                      ]);
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                      formRef?.current?.setFields([
                        {
                          name: "year", // Field name you want to set the error for
                          errors: [], // Assuming your error has a 'message' field
                        },
                      ]);
                    }
                  }
                }
              }
            }}>
            By Months
          </Checkbox>
          <Checkbox
            checked={isExactDateChecked}
            onChange={() => {
              if (
                !isYearChecked &&
                !isMonthChecked &&
                !isExactDateChecked === false
              ) {
                setIsExactChecked(!isExactDateChecked);
                setisMonthChecked(true);
                setIsYearChecked(true);
                if (error) {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                    }
                  }
                }
              } else {
                setIsExactChecked(!isExactDateChecked);
                setisMonthChecked(false);
                setIsYearChecked(false);
                if (error) {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                    }
                  }
                }
              }
            }}>
            Exact Date
          </Checkbox>
        </div>
        {isExactDateChecked ? (
          <div className="createReportModal-cont__form__formItemWrapperRange">
            <Form.Item
              className="createReportModal-cont__form__formItemWrapperRange__formItem"
              name="exact_date"
              rules={[
                {
                  required: true,
                  message: "Please select exact date",
                },
              ]}
              colon={false}>
              <RangePicker
                className="createReportModal-cont__form__formItemWrapperRange__formItem__RangePicker"
                onChange={() => {
                  if (error && (error["start_date"] || error["end_date"])) {
                    if (error["start_date"] && error["end_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                      dispatch(resetGenericReportError("end_date"));
                    } else if (error["start_date"]) {
                      dispatch(resetGenericReportError("start_date"));
                    } else if (error["end_date"]) {
                      dispatch(resetGenericReportError("end_date"));
                    }
                  }
                }}
                showTime={isMobile}
                allowEmpty={[false, true]}
                inputReadOnly={isMobile}
              />
            </Form.Item>
          </div>
        ) : (
          <div className="createReportModal-cont__form__formItemWrapper">
            {isYearChecked ? (
              <Form.Item
                className={classNames(
                  "createReportModal-cont__form__formItemWrapper__formItem",
                  {
                    createReportModal_GetallSpace_FormItem:
                      isYearChecked && !isMonthChecked,
                  }
                )}
                name="year"
                rules={[
                  {
                    required: true,
                    message: "Please select year",
                  },
                ]}
                label={
                  <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                    Years
                  </Typography.Text>
                }
                colon={false}>
                <Select
                  showSearch
                  allowClear
                  // mode="multiple"
                  options={years?.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  placeholder="Years"
                  onClear={() => {
                    setSelectedYear(null);
                  }}
                  onChange={(val) => {
                    if (formRef?.current?.getFieldValue("month")) {
                      formRef?.current?.setFieldValue("month", undefined);
                    }
                    setSelectedYear(val);
                    if (error && (error["start_date"] || error["end_date"])) {
                      if (error["start_date"] && error["end_date"]) {
                        dispatch(resetGenericReportError("start_date"));
                        dispatch(resetGenericReportError("end_date"));
                      } else if (error["start_date"]) {
                        dispatch(resetGenericReportError("start_date"));
                      } else if (error["end_date"]) {
                        dispatch(resetGenericReportError("end_date"));
                      }
                    }
                  }}
                />
              </Form.Item>
            ) : null}
            {isMonthChecked ? (
              <Form.Item
                className={classNames(
                  "createReportModal-cont__form__formItemWrapper__formItem",
                  {
                    createReportModal_GetallSpace_FormItem:
                      !isYearChecked && isMonthChecked,
                  }
                )}
                name="month"
                rules={[
                  {
                    required: true,
                    message: "Please select month",
                  },
                ]}
                label={
                  <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                    Months
                  </Typography.Text>
                }
                colon={false}>
                <Select
                  allowClear
                  showSearch
                  // mode="multiple"
                  options={months?.map((el, index) => ({
                    label: el,
                    value: el,
                    disabled:
                      (!isYearChecked ||
                        formRef?.current?.getFieldValue("year") >=
                          currentDate?.getFullYear()) &&
                      index > currentMonthIndex,
                  }))}
                  placeholder="Month"
                  onChange={() => {
                    if (isMonthChecked && !isYearChecked) {
                      if (error && (error["start_date"] || error["end_date"])) {
                        if (error["start_date"] && error["end_date"]) {
                          dispatch(resetGenericReportError("start_date"));
                          dispatch(resetGenericReportError("end_date"));
                        } else if (error["start_date"]) {
                          dispatch(resetGenericReportError("start_date"));
                        } else if (error["end_date"]) {
                          dispatch(resetGenericReportError("end_date"));
                        }
                      }
                    }
                  }}
                />
              </Form.Item>
            ) : null}
          </div>
        )}
        <Divider className="createReportModal-cont__form__divider">
          <Typography.Text className="createReportModal-cont__form__divider__text">
            Products
          </Typography.Text>
        </Divider>
        <div className="createReportModal-cont__form__formItemWrapper">
          <Form.Item
            className="createReportModal-cont__form__formItemWrapper__formItemType"
            name="report_type"
            rules={[
              {
                required: true,
                message: "Please select type",
              },
            ]}
            label={
              <Typography.Text className="createReportModal-cont__form__formItemWrapper__formItem__text">
                Type
              </Typography.Text>
            }
            colon={false}>
            <Select
              allowClear
              options={[
                {
                  label: "Data Report",
                  value: "Data Report",
                },
              ]}
              placeholder="Type"
              onChange={() => {
                if (error && error["report_type"]) {
                  dispatch(resetGenericReportError("report_type"));
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="createReportModal-cont__form__formItemWrapper">
          <Form.Item
            className="createReportModal-cont__form__formItemWrapper__formItem"
            name="metrc"
            colon={false}>
            <Checkbox
              checked={isByMetrc}
              onChange={() => {
                setIsByMetrc(!isByMetrc);
              }}>
              Metrc
            </Checkbox>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
}
