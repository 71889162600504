import React, { useEffect, useState } from "react";
import "./LandingDrawer.scss";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import close from "../../assets/CloseOutlined.svg";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import LandingSpin from "../LandingSpin/LandingSpin";
import { demoLogin } from "../../features/auth/authActions";
import { resetBlogsData } from "../../features/blogs/blogSlice";

export default function LandingDrawer({
  isOpened,
  direction,
  onClose,
  size,
  login,
  register,
  scrollIntoView,
  messageApi,
}) {
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  // Track if we're in the browser environment
  const [domReady, setDomReady] = useState(false);
  const openMessage = (type, text) => {
    messageApi.open({
      type: type,
      content: text,
      duration: 3,
    });
  };

  useEffect(() => {
    // This code only runs in the browser after component mounts
    setDomReady(true);
  }, []);

  const onBoxClick = (path) => {
    navigate(path);
    onClose();
  };

  return domReady && typeof document !== undefined
    ? createPortal(
        <Drawer
          className="landingDrawer-cont"
          zIndex={1000}
          open={isOpened}
          size={size}
          direction={direction}
          onClose={() => {
            if (!loading) {
              onClose();
            }
          }}>
          <div className="landingDrawer-cont__wrapper">
            <div className="landingDrawer-cont__wrapper__titleCont">
              <button
                className="landingDrawer-cont__wrapper__titleCont__btn-close"
                onClick={() => {
                  if (!loading) {
                    onClose();
                  }
                }}>
                <img src={close} alt="close" />
              </button>
              <Link
                to="/"
                onClick={() => {
                  if (!loading) {
                    onClose();
                  }
                }}>
                <img
                  className="landingDrawer-cont__wrapper__titleCont__img"
                  src={logo}
                  alt="Canasale"
                />
              </Link>
            </div>
            <div className="landingDrawer-cont__wrapper__content">
              <div
                className={classNames(
                  "landingDrawer-cont__wrapper__content__box",
                  {
                    "landingDrawer-cont__active-box":
                      pathname === "/" &&
                      hash !== "#pricing" &&
                      hash !== "#team",
                  }
                )}
                onClick={() => {
                  if (!loading) {
                    onBoxClick("/");
                  }
                }}>
                <Link>Home</Link>
              </div>
              <div
                className={classNames(
                  "landingDrawer-cont__wrapper__content__box",
                  {
                    "landingDrawer-cont__active-box":
                      pathname === "/" && hash === "#pricing",
                  }
                )}
                onClick={() => {
                  if (!loading) {
                    onBoxClick("/#pricing");
                    if (typeof scrollIntoView === "function") {
                      setTimeout(() => {
                        scrollIntoView("pricing");
                      }, 0);
                    }
                  }
                }}>
                <Link>Pricing</Link>
              </div>
              <div
                className={classNames(
                  "landingDrawer-cont__wrapper__content__box",
                  {
                    "landingDrawer-cont__active-box":
                      pathname === "/" && hash === "#team",
                  }
                )}
                onClick={() => {
                  if (!loading) {
                    onBoxClick("/#team");
                    if (typeof scrollIntoView === "function") {
                      setTimeout(() => {
                        scrollIntoView("team");
                      }, 0);
                    }
                  }
                }}>
                <Link>Features</Link>
              </div>
              <div
                className={classNames(
                  "landingDrawer-cont__wrapper__content__box",
                  {
                    "landingDrawer-cont__active-box":
                      pathname === "/archive" || pathname?.includes("blog"),
                  }
                )}
                onClick={() => {
                  if (!loading) {
                    onBoxClick("/archive");
                  }
                }}>
                <Link>Blog</Link>
              </div>
            </div>
            <div className="landingDrawer-cont__btnsWrapper">
              <button
                onClick={() => {
                  if (!loading) {
                    login();
                    onClose();
                  }
                }}>
                Login
              </button>
              <button
                onClick={() => {
                  if (!loading) {
                    register();
                    onClose();
                  }
                }}>
                Sign Up
              </button>
              <button
                className="tooltip-btn"
                onClick={() => {
                  dispatch(demoLogin())?.then((res) => {
                    if (res?.type === "demo/login/fulfilled") {
                      onClose();
                      dispatch(resetBlogsData());
                    } else if (res?.type === "demo/login/rejected") {
                      if (res?.payload?.Error) {
                        openMessage("error", res?.payload?.Error);
                      } else if (
                        !Array.isArray(res?.payload) &&
                        typeof res?.payload === "object" &&
                        res?.payload !== null
                      ) {
                        const error = res?.payload;
                        for (let key in error) {
                          if (typeof error[key] === "string") {
                            openMessage("error", error[key]);
                          } else if (
                            Array.isArray(error[key]) &&
                            error[key]?.length
                          ) {
                            if (
                              error[key][0] &&
                              typeof error[key][0] === "string"
                            ) {
                              openMessage("error", error[key][0]);
                            }
                          }
                          break;
                        }
                      }
                    }
                  });
                }}>
                {loading ? (
                  <LandingSpin
                    spinning={loading}
                    size={"20px"}
                    secondColor="#fff"
                  />
                ) : (
                  " Demo..."
                )}
                <span className="tooltip-text">Demo Login</span>
              </button>
            </div>
          </div>
        </Drawer>,
        document.body
      )
    : null;
}
