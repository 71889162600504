import { useEffect, useState } from "react";
import { theme, Image, Input, Button, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetSafeError } from "../features/safe/safeSlice";
import { getBarcodeItem, updateBarcodeMenuItem } from "../features/menu/menuActions";
import Utils from "../utils";

export default function useBarcode(
  formRef,
  menuId,
  subMenuId,
  isOpened,
  onCancel,
  handledItemId,
  allItems,
  defaultMode = false,
  onAddItem
) {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, title, text) => {
    api[type]({
      message: title,
      description: text,
      placement: "top",
    });
  };
  const {
    loading,
    menu: { results: menuOptions },
    subMenu: { results: subMenus },
  } = useSelector((state) => state?.menu);

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [tableData, setTableData] = useState({});
  const [isConfirmOpened, setIsConfrmOpened] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [searched_item, setSearched_item] = useState(null);
  const [isChecked, setIsChecked] = useState(defaultMode);
  const [scannedIds, setScannedIds] = useState({});
  function updateData(is_called_from_search, el) {
    if (!is_called_from_search) {
      setSearchValue("");
      setTableData((prevTableData) => {
        return {
          ...prevTableData,
          [el?.id]: { ...el, quantity: prevTableData[el?.id] ? (prevTableData[el?.id].quantity || 1) + 1 : 1 }
        }
      });
    } else {
      setSearched_item(el);
    }
  }

  const getItem = (id, is_called_from_search) => {
    setLoading(true);
    dispatch(getBarcodeItem(id)).then((item) => {
      if (item?.type === "get_barcode_item/fulfilled") {
        if (item?.payload) {
          const el = item?.payload;
          setScannedIds(old => ({
            ...old,
            [el.id]: true
          }))
          if (isChecked) {
            const found = allItems?.find(data => data.menu_item.id === el.id)
            if (found) {
              updateData(is_called_from_search, el)
            } else {
              openNotificationWithIcon("error", "Error", "Item not found");

            }
          } else {
            if (onAddItem) {
              onAddItem(el, id, 1); //tableData[el.id]?.quantity ? tableData[el.id]?.quantity + 1 :
              updateData(is_called_from_search, el)
            } else {
              dispatch(updateBarcodeMenuItem({ id })).then(() => {
                dispatch(getBarcodeItem(id)).then((it) => {
                  if (it?.type === "get_barcode_item/fulfilled") {
                    updateData(is_called_from_search, item?.payload)
                  } else {
                    openNotificationWithIcon("error", "Error", "Item not found");
                  }
                });
              });
            }
          }
        }
      } else if (item?.type === "get_barcode_item/rejected") {
        if (typeof item?.payload === "string") {
          openNotificationWithIcon("error", "Error", item?.payload);
        } else if (
          typeof item?.payload === "object" &&
          !Array.isArray(item?.payload)
        ) {
          const error = item?.payload;
          let foundString = false;
          for (let key in error) {
            if (typeof error[key] === "string") {
              openNotificationWithIcon("error", key, error[key]);
              foundString = true;
              break;
            }
          }
          if (!foundString) {
            openNotificationWithIcon("error", "Error", "Item not found.");
          }
        } else {
          openNotificationWithIcon("error", "Error", "Item not found.");
        }
        if (is_called_from_search) {
          setSearched_item(null);
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const handlePaste = (e) => {
      const paste = (e.clipboardData || window.clipboardData).getData("text");
      e.preventDefault();
      e.stopPropagation();
      getItem(paste); // TODO hardcode
    };

    // Attach the event listener
    document.addEventListener("paste", handlePaste);

    // Clean up the event listener
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [isChecked, JSON.stringify(tableData)], JSON.stringify(allItems));

  const onSearchInpChange = Utils?.debounce((e) => {
    setSearchValue(e.target.value);
    if (e.target.value) {
      getItem(e.target.value?.toLowerCase(), true);
    }
  }, 700);

  const onSearchKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event?.preventDefault();
      setSearchValue("");
      formRef.current.resetFields(["search"]);
      setSearched_item(null);
      if (searchValue && searched_item) {

        if (searched_item) {
          setTableData((prevTableData) => {
            // Check if the item already exists in the table
            return {
              ...prevTableData,
              [searched_item?.id]: { ...searched_item, quantity: prevTableData[searched_item?.id] ? (prevTableData[searched_item?.id].quantity || 1) + 1 : 1 }
            }
          });
        }
      }

      // Add your logic here for the Enter key
    }
  };
  // 3552
  function getTableColumns() {
    return !isChecked
      ? [
        {
          title: "",
          dataIndex: "image",
          key: "image",
          width: 56,
          className: "header-cell-small tabel table-row-text",
          render: (_, record) => {
            return (
              <Image
                src={record?.picture_url}
                alt="can"
                className="table-image"
              />
            );
          },
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          className: "table-row-text",
          sorter: true,
          width: 250,
        },
        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
          className: "table-row-text",
          sorter: true,
          width: 100,
          render: (_, record) => {
            return record?.brand?.value;
          },
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          className: "table-row-text",
          sorter: true,
          width: 130,
          render: (_, record) => {
            const parentCategory = Utils?.findParentCategory(record);
            return parentCategory?.name ? parentCategory?.name : "";
          },
        },
        {
          title: "Stock Count",
          dataIndex: "count",
          key: "count",
          className: "table-row-text",
          width: 100,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              (record?.variants[0]?.count || record?.variants[0]?.count === 0)
              ? record?.variants[0]?.count
              : "";
          },
        },
        {
          title: "Cost",
          dataIndex: "unit_cost",
          key: "cost",
          className: "tableCelltext-right",
          sorter: true,
          width: 110,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              record?.variants[0]?.cost
              ? `${Utils?.roundNumber(record?.variants[0]?.cost)}`
              : "$";
          },
        },
        {
          title: "Price",
          dataIndex: "unit_price",
          key: "price",
          className: "tableCelltext-right",
          sorter: true,
          width: 110,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              record?.variants[0]?.price
              ? `${Utils?.roundNumber(record?.variants[0]?.price)}`
              : "$";
          },
        },
        {
          title: "Unit",
          dataIndex: "grams_per_eighth",
          key: "unit",
          className: "table-row-text",
          width: 100,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              record?.variants[0]?.unit &&
              record?.variants[0]?.name
              ? Utils?.showProductDetail(record?.variants[0])
              : "";
          },
        },
        {
          title: "Added Quantity",
          dataIndex: "quantity",
          key: "quantity",
          className: "table-row-text-added",
          width: 100,
          fixed: "right",
          render: (_, record) => {
            return (
              // <Input
              //   key={record?.id}
              //   style={{ width: 100 }}
              //   placeholder="Count"
              //   value={record?.quantity}
              //   onChange={(e) => {
              //     const { value } = e.target;

              //     // Use a regex to allow only non-negative integers
              //     const isInteger = /^\d*$/.test(value);

              //     if (isInteger) {
              //       // Update the quantity for the specific record
              //       setTableData((prevTableData) =>
              //         prevTableData.map((item) =>
              //           item?.id?.toString() === record?.id?.toString()
              //             ? {
              //               ...item,
              //               quantity: value ? parseInt(value, 10) : "",
              //             }
              //             : item
              //         )
              //       );
              //     }
              //   }}
              // />
              <>
                <span style={{
                  color: token?.icons?.baseColor,
                  paddingLeft: 10
                }}>{record?.quantity ? '+ ' + record?.quantity : ''}</span >

              </>
            );
          },
        },
        // {
        //   title: "",
        //   dataIndex: "icon",
        //   key: "icon",
        //   width: 40,
        //   fixed: "right",
        //   render: (_, record) => {
        //     return (
        //       <Button
        //         size="small"
        //         icon={<DeleteOutlined />}
        //         onClick={() => {
        //           setIsConfrmOpened(true);
        //           setItemToDelete(record);
        //         }}
        //       />
        //     );
        //   },
        // },
      ]
      : [
        {
          title: "",
          dataIndex: "image",
          key: "image",
          width: 56,
          className: "header-cell-small tabel table-row-text",
          render: (_, record) => {
            return (
              <Image
                src={record?.picture_url}
                alt="can"
                className="table-image"
              />
            );
          },
        },
        {
          title: "Barcode",
          dataIndex: "id",
          key: "id",
          className: "table-row-text",
          width: 80,
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
          className: "table-row-text",
          width: 250,
        },
        {
          title: "Brand",
          dataIndex: "brand",
          key: "brand",
          className: "table-row-text",
          width: 100,
          render: (_, record) => {
            return record?.brand?.value;
          },
        },
        {
          title: "Category",
          dataIndex: "category",
          key: "category",
          className: "table-row-text",
          width: 130,
          render: (_, record) => {
            const parentCategory = Utils?.findParentCategory(record);
            return parentCategory?.name ? parentCategory?.name : "";
          },
        },
        {
          title: "Stock Count",
          dataIndex: "count",
          key: "count",
          className: "table-row-text",
          width: 100,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              (record?.variants[0]?.count || record?.variants[0]?.count === 0)
              ? record?.variants[0]?.count
              : "";
          },
        },
        {
          title: "Cost",
          dataIndex: "unit_cost",
          key: "cost",
          className: "tableCelltext-right",
          width: 110,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              record?.variants[0]?.cost
              ? `${Utils?.roundNumber(record?.variants[0]?.cost)}`
              : "$";
          },
        },
        {
          title: "Price",
          dataIndex: "unit_price",
          key: "price",
          className: "tableCelltext-right",

          width: 110,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              record?.variants[0]?.price
              ? `${Utils?.roundNumber(record?.variants[0]?.price)}`
              : "$";
          },
        },
        {
          title: "Unit",
          dataIndex: "grams_per_eighth",
          key: "unit",
          className: "table-row-text",
          width: 100,
          render: (_, record) => {
            return record?.variants?.length &&
              record?.variants[0] &&
              record?.variants[0]?.unit &&
              record?.variants[0]?.name
              ? Utils?.showProductDetail(record?.variants[0])
              : "";
          },
        },
        {
          title: "Items left",
          dataIndex: "quantity",
          key: "quantity",
          className: "tableCelltext-right",
          width: 100,
          fixed: "right",
          render: (_, record) => {
            const found = tableData[record.id]?.quantity || 0
            return record?.quantity - found
          },
        }
      ];
  }

  useEffect(() => {
    if (isOpened && handledItemId) {
      getItem(handledItemId);
    }
  }, [isOpened, handledItemId]);

  const removeItem = (item) => {
    setTableData((prev) =>
      prev?.filter((el) => el?.id?.toString() !== item?.id?.toString())
    );
    setIsConfrmOpened(false);
    setItemToDelete(null);
  };

  // useEffect(() => {
  //   if (isOpened) {
  //     if (error && !loading) {
  //       for (let key in error) {
  //         formRef?.current?.setFields([
  //           {
  //             name: key, // Field name you want to set the error for
  //             errors: [error[key][0]], // Assuming your error has a 'message' field
  //           },
  //         ]);
  //       }
  //     }
  //   }
  // }, [safeLoading, error]);

  // useEffect(() => {
  //   if (isOpened) {
  //     if (success) {
  //       onCancel();
  //       formRef.current.resetFields();
  //       dispatch(resetSafeError());
  //     }
  //   }
  // }, [success, dispatch]);

  return {
    formRef,
    token,
    isChecked,
    setIsChecked,
    setLoading,
    loading,
    setSearchValue,
    onSearchInpChange,
    onSearchKeyDown,
    setSearched_item,
    getTableColumns,
    dispatch,
    resetSafeError,
    setTableData,
    tableData,
    isLoading,
    setIsConfrmOpened,
    isConfirmOpened,
    setItemToDelete,
    itemToDelete,
    removeItem,
    contextHolder,
    scannedIds
  };
}
