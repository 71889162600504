import { theme } from "antd";
import { useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSubMenu, editSubMenu } from "../features/menu/menuActions";


export default function useMenuModal(isOpened, subMenu) {
  const dispatch = useDispatch();
  const [inpVal, setInpVal] = useState("");
  const formRef = useRef(null);
  const { error, loading, menu } = useSelector((state) => state?.menu);
  const { results: subMenus } = useSelector((state) => state.menu.subMenu);
  const { useToken } = theme;
  const token = useToken()?.token;
  const {
    token: { Modal: modal, Button: button },
  } = useToken();
  const [formData, setFormData] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [isMenuChecked, setIsMenuChecked] = useState(false);
  const foundMenu = useMemo(() => {
    if (isOpened) {
      if (
        typeof subMenu?.menu === "object" &&
        subMenu?.menu &&
        subMenu?.menu?.name
      ) {
        return subMenu?.menu;
      } else if (subMenu?.id && typeof subMenu?.menu !== "object") {
        const foundMenu = menu?.results?.find(
          (men) => men?.id?.toString() === subMenu?.menu?.toString()
        );
        return foundMenu;
      }
    }
  }, [isOpened, subMenu]);

  const oninpChnage = (e) => {
    setInpVal(e.target.value);
  };

  const onChackboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const onMenuCheckboxChange = (e) => {
    setIsMenuChecked(e.target.checked);
  };

  return {
    modal,
    button,
    inpVal,
    oninpChnage,
    setInpVal,
    formRef,
    error,
    loading,
    dispatch,
    addSubMenu,
    editSubMenu,
    subMenus,
    formData,
    setFormData,
    isChecked,
    onChackboxChange,
    onMenuCheckboxChange,
    setIsChecked,
    isMenuChecked,
    setIsMenuChecked,
    token,
    foundMenu
  };
}
