import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "./registerActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  registrationInfo: {},
};

const registerSlice = createSlice({
  name: "register",
  initialState,
  success: false,
  reducers: {
    resetError: (state, action) => {
      if (action.payload !== "All") {
        let newError = state?.error;
        delete newError[action.payload];
        if (Object.keys(newError).length === 0) {
          state.error = null;
          state.success = false;
          state.loading = false;
        } else {
          state.error = newError;
          state.success = false;
          state.loading = false;
        }
      } else {
        state.error = null;
        state.success = false;
        state.loading = false;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.registrationInfo = action.payload;
        state.success = true;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
        state.success = false;
      });
  },
});
export const { resetError } = registerSlice.actions;
export default registerSlice.reducer;
