import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../config";
import LocalStorageService from "../../common/services/LocalStorageService";
import axiosClient from "../../common/api/request";

export const userLogin = createAsyncThunk(
  "auth/login",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/auth/`,
        {
          ...params,
          email: params?.email?.trim(),
        },
        config
      );

      // store user's token in local storage
      LocalStorageService.set("authorizationData", {
        access: data.access,
        refresh: data.refresh,
        is_superuser: data.user?.is_superuser,
        group_permissions: data.user?.group_permissions,
        role: data.role,
        driver: data.driver,
      });
      // LocalStorageService.set("authorizationData",data)
      return data;
    } catch (error) {
      if (error?.response && error?.response?.data?.detail) {
        return rejectWithValue(error.response.data?.detail);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const demoLogin = createAsyncThunk(
  "demo/login",
  async (params, { rejectWithValue }) => {
    try {
      const data = await axiosClient.post(
        `${config.API_URL}/demo_auth/`,
        params,
        config
      );

      // store user's token in local storage
      LocalStorageService.set("authorizationData", {
        access: data?.access,
        refresh: data?.refresh,
        is_superuser: data?.user?.is_superuser,
        group_permissions: data?.user?.group_permissions,
        role: data?.role,
        driver: data?.driver,
      });
      return data;
    } catch (error) {
      if (
        error?.response &&
        error?.response?.data &&
        typeof error?.response?.data === "object"
      ) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue({
          Error: error?.message,
        });
      }
    }
  }
);
