import React, { useRef } from "react";
import "./BarcodeModal.scss";
import { Modal, Form, Table, Typography, Checkbox } from "antd";
import useBarcode from "../../hooks/useBarcode";
import Search from "antd/es/input/Search";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import classNames from "classnames";

export default function BarcodeModal({
  title,
  isOpened,
  onOk,
  onCancel,
  menuId,
  subMenuId,
  handledItemId,
  allItems,
  defaultMode,
  onAddItem,
  getALlItems,
  isInventory,
  isBag
}) {
  const formRef = useRef();
  const {
    token,
    isChecked,
    setIsChecked,
    setLoading,
    loading,
    error,
    dispatch,
    resetSafeError,
    getTableColumns,
    safeLoading,
    setSearchValue,
    onSearchInpChange,
    onSearchKeyDown,
    setSearched_item,
    tableData,
    setTableData,
    isLoading,
    setIsConfrmOpened,
    isConfirmOpened,
    setItemToDelete,
    itemToDelete,
    removeItem,
    contextHolder,
    scannedIds
  } = useBarcode(formRef, menuId, subMenuId, isOpened, onCancel, handledItemId, allItems, defaultMode, onAddItem);
  // const filtered = tableData.filter(data => data.id === a.id || data.id === b.id)
  if (isInventory) {
    return <></>
  }

  return (
    <>
      {contextHolder}
      <Modal
        className="barcodeModal-cont"
        open={isOpened}
        title={
          <div className="barcodeModal-cont__titleWrapper">
            <div>
              <Typography.Text className="barcodeModal-cont__titleWrapper__title">
                {isChecked ? 'Cycle Count' : title}
              </Typography.Text>
              {isChecked && <Typography.Text >
                {/* left */}
              </Typography.Text>}
            </div>
            {/* {!isBag && <Checkbox
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);

                if (e.target.checked) {
                  getALlItems()
                }
                setTableData([])
              }}>
              Inventory Check
            </Checkbox>} */}
          </div>
        }
        centered={true}
        okText="Add Items"
        cancelText="Close"
        width={1500}
        closeIcon={false}
        onOk={() => {
          formRef.current.submit();
          if (error) {
            dispatch(resetSafeError());
          }
        }}
        onCancel={() => {
          onCancel();
          formRef.current.resetFields();
          dispatch(resetSafeError());
          setLoading(false);
          setSearchValue("");
          setTableData([]);
          setIsConfrmOpened(false);
          setItemToDelete(null);
          setSearched_item(null);
          // setIsChecked(false);
        }}
        style={{
          borderTop: `2px solid ${token?.Button?.colorPrimary}`,
        }}
        cancelButtonProps={{
          className: "barcodeModal-cont__btn",
          size: "small",
        }}
        okButtonProps={{
          size: "small",
          htmlType: "submit",
          type: "primary",
          className: classNames("barcodeModal-cont__btn", {
            "barcodeModal-cont__diplayNone": true,
          }),
          loading: loading || safeLoading,
          disabled: error ? Object.keys(error)?.length !== 0 : false,
        }}>
        <Form
          className="barcodeModal-cont__form"
          layout="vertical"
          ref={formRef}
          onFinish={() => {
            console.log("items onfinish", tableData);
            const params = tableData
            // ?.map((el) => ({
            //   id: el?.id,
            //   quantity: el?.quantity,
            // }));
            onOk(params, () => {
              setLoading(false);
              setSearchValue("");
              setTableData({});
              setIsConfrmOpened(false);
              setItemToDelete(null);
              setSearched_item(null);
              // setIsChecked(false);
            });
          }}
          onFinishFailed={(error) => {
            console.log("onfinishFailed", error);
          }}>
          <Form.Item name="search">
            <Search
              style={{
                maxHeight: "40px",
              }}
              disabled={loading}
              placeholder="Barcode"
              allowClear
              size="large"
              onChange={onSearchInpChange}
              onKeyDown={onSearchKeyDown}
            />
          </Form.Item>
          <Form.Item>
            <Table
              className="barcodeModal-cont__table"
              loading={isLoading}
              columns={getTableColumns()}
              bordered
              dataSource={isChecked ? allItems?.map(bag => ({
                ...bag.menu_item,
                quantity: bag.quantity
              })).sort((a, b) => {
                const quantity1 = tableData[a.id]?.quantity || 0
                const quantity2 = tableData[b.id]?.quantity || 0
                if ((a.quantity - quantity1) && !(b.quantity - quantity2)) {
                  return -1
                }
                if ((b.quantity - quantity2) && !(a.quantity - quantity1)) {
                  return 1
                }
                if (scannedIds[a.id] && scannedIds[b.id]) {
                  return 0;
                }
                if (scannedIds[a.id] && !scannedIds[b.id]) {
                  return -1
                }
                if (!scannedIds[a.id] && scannedIds[b.id]) {
                  return 1
                }
                return 0
              }).filter((it, ind) => ind < 10) : Object.values(tableData).map((item) => {
                return {
                  ...item,
                  key: item.id,
                };
              })}
              pagination={false}
              scroll={{
                x: "1250px",
              }}
            />
          </Form.Item>
        </Form>
        <ConfirmModal
          title="Are you sure ?"
          subTitle="You want to delete the item ?"
          isOpened={isConfirmOpened}
          onCancel={() => {
            setIsConfrmOpened(false);
            setItemToDelete(null);
          }}
          onOk={() => {
            removeItem(itemToDelete);
          }}
        />
      </Modal>
    </>
  );
}
