import React from "react";
import { TreeSelect, Tooltip } from "antd";

export default function CustomTreeSelect({
  style = {},
  className = "",
  size = "large",
  placeholder = "",
  maxTagCount = "responsive",
  treeData,
  value = [],
  onTreeSelectChange,
  treeDefaultExpandAll,
}) {
  return (
    <TreeSelect
      key={"treeSelect"}
      style={{ width: "100%", ...style }}
      className={className}
      size={size}
      placeholder={placeholder}
      multiple
      showSearch
      allowClear
      maxTagCount={maxTagCount}
      maxTagPlaceholder={(omittedValues) => (
        <Tooltip
          overlayStyle={{
            pointerEvents: "none",
          }}
          title={omittedValues?.map(({ label }) => label)?.join(", ")}>
          <span>+{omittedValues?.length}...</span>
        </Tooltip>
      )}
      treeDefaultExpandAll={treeDefaultExpandAll}
      treeData={treeData}
      value={value}
      onChange={(organizations) => {
        onTreeSelectChange(organizations);
      }}
      treeNodeFilterProp="title"
      treeLine={true}
    />
  );
}
