import { useEffect, useMemo, useRef, useState } from "react";
import { theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../utils";
import { getTeamMembers } from "../features/teamMembers/teamMembersAction";
import { resetOrderRoomError } from "../features/orders/ordersSlice";
import useWindowWidth from "./useWindowWidth";
const { numberValidator } = Utils;

export default function useOrderTabModal(
  isOpened,
  onCancel,
  roomDetails,
  callWhenOrderRoomUpdated,
  callWhenOrderRoomCreated
) {
  const { useToken } = theme;
  const { token } = useToken();
  const {
    menu: { results: menus },
  } = useSelector((state) => state?.menu);
  const { isMobile } = useWindowWidth();
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const [formData, setFormData] = useState([]);
  const [isByMetrc, setIsByMetrc] = useState(false);
  const { possibleLocations } = useSelector((state) => state?.locations);
  const {
    teamUsers: { meta: metaUsers, results: users },
    loading,
  } = useSelector((state) => state?.teamMembers);
  const { roomLoading, roomError, roomSuccess, orderRooms } = useSelector(
    (state) => state?.orders
  );

  const menuNames = useMemo(() => {
    if (isOpened && menus?.length) {
      return menus?.map((menu) =>
        menu?.name === "weedgravity"
          ? "WEED_GRAVITY"
          : menu?.name?.toUpperCase()
      );
    }
  }, [menus, isOpened]);

  const filterOption = (input, option) =>
    (option?.label ?? "")?.toLowerCase()?.includes(input?.toLowerCase());

  useEffect(() => {
    if (roomDetails) {
      setFormData([
        {
          name: "name",
          value: roomDetails?.name ? roomDetails?.name : "",
        },
        {
          name: "locations",
          value: roomDetails?.locations ? roomDetails?.locations : "",
        },
        {
          name: "delta",
          value:
            roomDetails?.delta || roomDetails?.delta === 0
              ? roomDetails?.delta
              : "",
        },
        {
          name: "delta_unit",
          value: roomDetails?.delta_unit ? roomDetails?.delta_unit : "",
        },
        {
          name: "sources",
          value: roomDetails?.sources ? roomDetails?.sources : "",
        },
        {
          name: "locations",
          value: roomDetails?.locations ? roomDetails?.locations : "",
        },
        {
          name: "users",
          value: roomDetails?.users ? roomDetails?.users : "",
        },
      ]);
      if (roomDetails?.is_metrc) {
        setIsByMetrc(true);
      }
    }
  }, [isOpened, roomDetails]);

  useEffect(() => {
    if (isOpened) {
      if (roomError && !roomLoading) {
        for (let key in roomError) {
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [roomError[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [roomError, roomLoading]);

  useEffect(() => {
    if (isOpened) {
      if (roomSuccess) {
        onCancel();
        dispatch(resetOrderRoomError());
        formRef.current.resetFields();
        setIsByMetrc(false);
        if (typeof callWhenOrderRoomUpdated === "function" && roomDetails) {
          callWhenOrderRoomUpdated({
            sources: roomDetails?.sources,
            locations: roomDetails?.locations,
          });
        } else {
          if (typeof callWhenOrderRoomCreated === "function" && !roomDetails) {
            callWhenOrderRoomCreated(orderRooms[orderRooms?.length - 1]?.id);
          }
        }
      }
    }
  }, [roomSuccess]);

  return {
    token,
    formRef,
    formData,
    possibleLocations,
    numberValidator,
    loading,
    metaUsers,
    dispatch,
    getTeamMembers,
    roomLoading,
    filterOption,
    resetOrderRoomError,
    roomError,
    users,
    isByMetrc,
    setIsByMetrc,
    isMobile,
    menuNames,
  };
}
