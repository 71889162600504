import React from "react";
import "./OrderDrawer.scss";
import {
  Drawer,
  Typography,
  Collapse,
  Table,
  Button,
  Divider,
  Dropdown,
  Spin,
  Input,
} from "antd";
import {
  DownOutlined,
  EditTwoTone,
  CloseCircleOutlined,
  CheckCircleTwoTone,
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import useOrderDrawer from "../../../../hooks/useOrderDrawer";
import classNames from "classnames";
import { AddBlackList, ConfirmModal } from "../../../../modals";
import Can from "../../../../common/services/permissions/Can";
import TextArea from "antd/es/input/TextArea";

export default function OrderDrawer({
  isOpened,
  order,
  close,
  isLoading,
  isSearchBarVisible,
  updateOrderWhenCustomerDetailsChanged,
  setselectedTabKey,
  orderSuccess,
  getOrdersWithParams,
  updateReportwhenOrderItemChanged,
}) {
  const {
    token,
    tableColumns,
    tableData,
    items,
    dropdownItemsDriver,
    dropdownItemsActions,
    dropdownItemsCards,
    assignedDriver,
    // setAssignedDriver,
    drivers,
    ammendOrder,
    orderStatus,
    paymentType,
    contextHolder,
    information,
    // setInforMation,
    setCurrentField,
    ammendOrderStatus,
    currentField,
    isEditTotalOPened,
    setIsEditTotalOpened,
    inpVal,
    setInpVal,
    amendPaymentType,
    openNotificationWithIcon,
    ammendGrandToatal,
    isSmallDesktop,
    isMobile,
    secondCollapseActiveKey,
    setSecondCollapseActiveKey,
    firstCollapseActiveKey,
    setFirstCollapseActiveKey,
    setEditOrderDetails,
    setValsOfOrderDetails,
    options,
    resetError,
    resetPatientError,
    dispatch,
    patientError,
    setupdatedField,
    selectedRowKey,
    handleAddItem,
    setIsDeleteOrderItemModalOpened,
    isDeleteOrderItemModalOpened,
    setOrderItemMode,
    setselectedRowKey,
    orderItemMode,
    deleteOrderItem,
    // selectedMenuItem,
    // receivedMenuItems,
    // selectedVariant,
    // menuItemCount,
    setTableData,
    setSelectedMenuItem,
    setSelectedVariant,
    setMenuItemCount,
    setSearchMenuItemVal,
    // updatedField,
    OrderIsNotEditable,
    error,
    setThirthCollapseActiveKey,
    thirthCollapseActiveKey,
    is_note_text_area_visible,
    set_is_note_text_area_visible,
    note,
    setNote,
    updateCustomerNote,
    isBlackListModalOpened,
    setIsBlackListModalOpened,
    onBlackListModalOk,
    isConfirmOpened,
    setIsConfirmOpened,
    removeFromBL,
    applyDiscount
  } = useOrderDrawer(
    order,
    close,
    isOpened,
    isLoading,
    getOrdersWithParams,
    setselectedTabKey,
    // error,
    updateOrderWhenCustomerDetailsChanged,
    // orderSuccess,
    updateReportwhenOrderItemChanged
  );

  return (
    <>
      {contextHolder}
      <Drawer
        className={classNames("order-drawer", {
          orderDrawerBigMargin: isSearchBarVisible && isMobile,
        })}
        headerStyle={{
          backgroundColor: information?.customer?.blacklist
            ? token?.BLP?.colorBg
            : "",
        }}
        title={
          <Typography.Text
            className="order-drawer__title"
            style={{
              color: information?.customer?.blacklist ? token?.BLP?.colorText : "",
            }}>
            {`${information?.order_id ? information?.order_id : ""}`}
          </Typography.Text>
        }
        closeIcon={
          <CloseOutlined
            style={{
              color: information?.customer?.blacklist ? token?.BLP?.colorText : "",
            }}
          />
        }
        placement="right"
        open={isOpened}
        onClose={() => {
          close();
          setCurrentField("");
          setEditOrderDetails({
            Address: false,
            "Phone Number": false,
            "Promotion Code": false,
          });
          setValsOfOrderDetails({
            Address: "",
            "Phone Number": order?.customer?.phone_number
              ? order?.customer?.phone_number
              : "+1",
            "Promotion Code": order?.discount ? order?.discount : null,
          });
          setupdatedField(null);
          setselectedRowKey(null);
          setSelectedMenuItem(null);
          setSelectedVariant(null);
          setMenuItemCount(null);
          setOrderItemMode("");
          setSearchMenuItemVal("");
          set_is_note_text_area_visible(false);
          setNote("");
          if (error) {
            dispatch(resetError());
          }
          if (patientError) {
            dispatch(resetPatientError());
          }
        }}
        // size={isMobile ? "default" : "large"}
        maskStyle={{
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        }}
        width={isSmallDesktop || isMobile ? 700 : 880}>
        <div className="order-drawer__collapseCont">
          <Collapse
            className="order-drawer__collapseCont__collapse"
            activeKey={firstCollapseActiveKey}
            onChange={() => {
              if (firstCollapseActiveKey?.length) {
                setFirstCollapseActiveKey([]);
              } else {
                setFirstCollapseActiveKey(["table"]);
              }
            }}
            expandIcon={(props) => {
              return (
                <DownOutlined
                  style={{
                    color: token?.icons?.baseColor,
                    transform: props?.isActive
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s",
                    fontSize: "24px",
                  }}
                />
              );
            }}
            ghost={true}
            bordered={false}
            style={{
              backgroundColor: "transparent",
              borderTopColor: token?.Collapse?.borderTopColor,
              boxShadow: token?.Collapse?.boxShadow,
            }}
            expandIconPosition="end"
            size="middle"
            items={[
              {
                key: "table",
                label: (
                  <Typography.Text className="order-drawer__collapseCont__collapse__title">
                    Items
                  </Typography.Text>
                ),
                children: (
                  <>
                    {OrderIsNotEditable ? null : (
                      <Button
                        icon={<PlusOutlined />}
                        size="small"
                        style={{
                          marginBottom: "10px",
                        }}
                        disabled={tableData?.find((el) => el?.locale)}
                        onClick={handleAddItem}
                        loading={isLoading}>
                        Add item
                      </Button>
                    )}
                    <Table
                      className="order-drawer__collapseCont__collapse__table"
                      columns={tableColumns}
                      dataSource={tableData}
                      pagination={false}
                      bordered
                      loading={isLoading}
                      scroll={
                        isMobile || isSmallDesktop
                          ? {
                            x: "800px",
                          }
                          : null
                      }
                    />
                  </>
                ),
              },
            ]}
          />
          <Collapse
            className="order-drawer__collapseCont__collapse"
            activeKey={secondCollapseActiveKey}
            onChange={() => {
              if (secondCollapseActiveKey?.length) {
                setSecondCollapseActiveKey([]);
              } else {
                setSecondCollapseActiveKey(["item"]);
              }
            }}
            expandIcon={(props) => {
              return (
                <DownOutlined
                  style={{
                    color: token?.icons?.baseColor,
                    transform: props?.isActive
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s",
                    fontSize: "24px",
                  }}
                />
              );
            }}
            ghost={true}
            bordered={false}
            style={{
              backgroundColor: "transparent",
              borderTopColor: token?.Collapse?.borderTopColor,
              boxShadow: token?.Collapse?.boxShadow,
            }}
            expandIconPosition="end"
            size="middle"
            items={[
              {
                key: "item",
                label: (
                  <Typography.Text className="order-drawer__collapseCont__collapse__title">
                    Information
                  </Typography.Text>
                ),
                children: items,
              },
            ]}
          />
          <Collapse
            className="order-drawer__collapseCont__collapse"
            activeKey={thirthCollapseActiveKey}
            onChange={() => {
              if (thirthCollapseActiveKey?.length) {
                setThirthCollapseActiveKey([]);
              } else {
                setThirthCollapseActiveKey(["notes"]);
              }
            }}
            expandIcon={(props) => {
              return (
                <DownOutlined
                  style={{
                    color: token?.icons?.baseColor,
                    transform: props?.isActive
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s",
                    fontSize: "24px",
                  }}
                />
              );
            }}
            ghost={true}
            bordered={false}
            style={{
              backgroundColor: "transparent",
              borderTopColor: token?.Collapse?.borderTopColor,
              boxShadow: token?.Collapse?.boxShadow,
            }}
            expandIconPosition="end"
            size="middle"
            items={[
              {
                key: "notes",
                label: (
                  <Typography.Text className="order-drawer__collapseCont__collapse__title">
                    Notes
                  </Typography.Text>
                ),
                children: (
                  <div className="order-drawer__collapseCont__collapse__notesCont">
                    {!is_note_text_area_visible ? (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "8px",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}>
                        <Typography.Text>
                          {order?.customer_note ? order.customer_note : ""}
                        </Typography.Text>
                        <Button
                          type="text"
                          size="small"
                          icon={
                            <EditOutlined
                              style={{
                                color: token?.colorPrimary,
                                fontSize: "18px",
                              }}
                            />
                          }
                          onClick={() => {
                            set_is_note_text_area_visible(true);
                            if (error && error["customer_note"]) {
                              dispatch(resetError("customer_note"));
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <>
                        <TextArea
                          rows={3}
                          placeholder="Note"
                          style={{
                            resize: "none",
                          }}
                          status={error?.customer_note ? "error" : ""}
                          value={note}
                          onChange={(e) => {
                            setNote(e?.target?.value);
                            if (error && error["customer_note"]) {
                              dispatch(resetError("customer_note"));
                            }
                          }}
                          disabled={is_note_text_area_visible && isLoading}
                        />
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: "8px",
                          }}>
                          <Button
                            size="small"
                            disabled={is_note_text_area_visible && isLoading}
                            onClick={() => {
                              set_is_note_text_area_visible(false);
                              setNote(
                                order?.customer_note ? order?.customer_note : ""
                              );
                              if (error && error["customer_note"]) {
                                dispatch(resetError("customer_note"));
                              }
                            }}>
                            Cancel
                          </Button>
                          <Button
                            type="primary"
                            size="small"
                            loading={is_note_text_area_visible && isLoading}
                            disabled={error?.customer_note}
                            onClick={() => {
                              updateCustomerNote({
                                id: order?.id,
                                customer_note: note,
                              });
                            }}>
                            Save
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                ),
              },
            ]}
          />
        </div>
        <div className="order-drawer__tottal-cont">
          <div className="order-drawer__tottal-cont__title-cont">
            <Typography.Text className="order-drawer__tottal-cont__title-cont__title">
              Total Amount
            </Typography.Text>
            {isEditTotalOPened ? (
              <div className="order-drawer__tottal-cont__title-cont__rate-cont">
                <Input
                  className="order-drawer__tottal-cont__title-cont__rate-cont__inp"
                  placeholder="Total amount"
                  size="small"
                  value={inpVal}
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      if (Number(e.target.value > 0 || e.target.value === "")) {
                        setInpVal(e.target.value);
                      }
                    }
                  }}
                />
                <Button
                  type="text"
                  size="small"
                  onClick={() => {
                    setIsEditTotalOpened(false);
                    setInpVal("");
                  }}
                  icon={
                    <CloseCircleOutlined
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                />
                <Button
                  type="text"
                  size="small"
                  icon={
                    <CheckCircleTwoTone
                      twoToneColor={token?.icons?.baseColor}
                      onClick={() => {
                        if (inpVal !== "") {
                          setIsEditTotalOpened(false);
                          setInpVal("");
                          setCurrentField("TotalAmount");
                          // dispatch(
                          //   calculateOrder({
                          //     id: order?.id,
                          //     grand_total: inpVal,
                          //     order_items: order?.order_items.map((el) => ({
                          //       variant_id: el?.variant?.id,
                          //       quantity: el?.quantity,
                          //     })),
                          //   })
                          // );
                          ammendGrandToatal({
                            grand_total: inpVal,
                          });
                        } else {
                          openNotificationWithIcon(
                            "error",
                            "Check Total amount",
                            "You must write a number"
                          );
                        }
                      }}
                      style={{
                        fontSize: "18px",
                      }}
                    />
                  }
                />
              </div>
            ) : currentField === "TotalAmount" && isLoading ? (
              <Spin />
            ) : (
              <div className="order-drawer__tottal-cont__title-cont__rate-cont">
                <Typography.Text
                  className="order-drawer__tottal-cont__title-cont__rate-cont__rate"
                  style={{
                    color: token?.Typography?.baseColorText,
                  }}>
                  $ {order?.grand_total}
                </Typography.Text>
                {OrderIsNotEditable ? null : (
                  <Button
                    type="text"
                    size="small"
                    onClick={() => {
                      setIsEditTotalOpened(true);
                    }}
                    icon={
                      <EditTwoTone
                        twoToneColor={token?.icons?.baseColor}
                        style={{
                          fontSize: "18px",
                        }}
                      />
                    }
                  />
                )}
              </div>
            )}
          </div>
          <div className="order-drawer__tottal-cont__options">
            <div className="order-drawer__tottal-cont__options__wrapper">
              <Typography.Text className="order-drawer__tottal-cont__options__wrapper__text">
                Driver
              </Typography.Text>
              {isLoading && currentField === "Driver" ? (
                <Spin />
              ) : (
                <Can
                  I={"change"}
                  a={"order"}
                  fallback={
                    <Typography.Text className="order-drawer__tottal-cont__options__wrapper__text">
                      {assignedDriver ? assignedDriver : ""}
                    </Typography.Text>
                  }>
                  <Dropdown
                    className="order-drawer__tottal-cont__options__wrapper__dropdown"
                    trigger={["click"]}
                    overlayStyle={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      maxWidth: "200px",
                    }}
                    menu={{
                      items: dropdownItemsDriver,
                      onClick: ({ key }) => {
                        const driver = drivers?.find(
                          (el) => el?.id === Number(key)
                        );
                        setCurrentField("Driver");
                        // setAssignedDriver(
                        //   `${driver?.first_name} ${driver?.last_name}`
                        // );
                        // setInforMation({
                        //   ...information,
                        //   driver:`${driver?.first_name} ${driver?.last_name}`
                        // })
                        ammendOrder(driver);
                      },
                    }}>
                    <div className="order-drawer__tottal-cont__options__wrapper__dropdown__item">
                      <Typography.Text
                        className="order-drawer__tottal-cont__options__wrapper__dropdown__item__text"
                        style={{
                          color: `${token?.Typography?.baseColorText}`,
                        }}>
                        {assignedDriver ? assignedDriver : "Assign Driver"}
                      </Typography.Text>
                      <DownOutlined
                        style={{
                          color: `${token?.icons?.baseColor}`,
                        }}
                      />
                    </div>
                  </Dropdown>
                </Can>
              )}
            </div>
            <Divider
              className="order-drawer__tottal-cont__options__wrapper__divider"
              type="horizontal"
            />
            <div className="order-drawer__tottal-cont__options__wrapper">
              <Typography.Text className="order-drawer__tottal-cont__options__wrapper__text">
                Actions
              </Typography.Text>
              {isLoading && currentField === "Action" ? (
                <Spin />
              ) : (
                <Dropdown
                  className="order-drawer__tottal-cont__options__wrapper__dropdownact"
                  trigger={["click"]}
                  menu={{
                    items: dropdownItemsActions,
                    onClick: ({ key }) => {
                      const foundAction = dropdownItemsActions.find(
                        (el) => key === el?.key
                      );
                      setCurrentField("Action");
                      // setOrderStatus(foundAction?.label);
                      ammendOrderStatus({
                        status: foundAction?.key,
                      });
                    },
                  }}>
                  <div className="order-drawer__tottal-cont__options__wrapper__dropdownact__item">
                    <Typography.Text
                      className="order-drawer__tottal-cont__options__wrapper__dropdownact__item__text"
                      style={{
                        color: `${token?.Typography?.baseColorText}`,
                      }}>
                      {orderStatus ? orderStatus : "Unassigned"}
                    </Typography.Text>
                    <DownOutlined
                      style={{
                        color: `${token?.icons?.baseColor}`,
                      }}
                    />
                  </div>
                </Dropdown>
              )}
            </div>
            <Divider
              className="order-drawer__tottal-cont__options__wrapper__divider"
              type="horizontal"
            />
            <div className="order-drawer__tottal-cont__options__wrapper">
              <Typography.Text className="order-drawer__tottal-cont__options__wrapper__text">
                Payment Type
              </Typography.Text>
              {isLoading && currentField === "Card" ? (
                <Spin />
              ) : (
                <Can
                  I={"change"}
                  a={"order"}
                  fallback={
                    <Typography.Text className="order-drawer__tottal-cont__options__wrapper__text">
                      {paymentType
                        ? options?.find(
                          (payment) => payment?.key === paymentType
                        )?.label
                        : ""}
                    </Typography.Text>
                  }>
                  <Dropdown
                    className="order-drawer__tottal-cont__options__wrapper__dropdownCard"
                    trigger={["click"]}
                    menu={{
                      items: dropdownItemsCards,
                      onClick: ({ key }) => {
                        const faoundPaymentType = dropdownItemsCards.find(
                          (el) => key === el?.key
                        );
                        setCurrentField("Card");
                        amendPaymentType({
                          payment_type: faoundPaymentType?.key,
                        });
                      },
                    }}>
                    <div className="order-drawer__tottal-cont__options__wrapper__dropdownCard__item">
                      <Typography.Text
                        className="order-drawer__tottal-cont__options__wrapper__dropdownCard__item__text"
                        style={{
                          color: `${token?.Typography?.baseColorText}`,
                        }}>
                        {paymentType
                          ? options?.find(
                            (payment) => payment?.key === paymentType
                          )?.label
                          : "Payment Type"}
                      </Typography.Text>
                      <DownOutlined
                        style={{
                          color: `${token?.icons?.baseColor}`,
                        }}
                      />
                    </div>
                  </Dropdown>
                </Can>
              )}
            </div>
            <Divider
              className="order-drawer__tottal-cont__options__wrapper__divider"
              type="horizontal"
            />
            {!order?.telegram_credits_used && order?.telegram_credits ?<div className="order-drawer__tottal-cont__options__wrapper">
              <div className="order-drawer__tottal-cont__options__wrapper_discount">
                <Typography.Text className="order-drawer__tottal-cont__options__wrapper__text">
                  User has {order?.telegram_credits} credits
                </Typography.Text>
                <Button
                loading={isLoading}
                onClick={() => applyDiscount(order?.id)}
                className="order-drawer__tottal-cont__options__wrapper_discount_button"
                  type="primary">
                  Apply
                </Button>
              </div>
            </div>: <></>}
          </div>
        </div>
        <ConfirmModal
          title="Are you sure ?"
          subTitle={
            tableData?.find((el) => el?.key === selectedRowKey)?.locale
              ? "You want to delete the order row ?"
              : "You want to delete the order item ?"
          }
          isOpened={isDeleteOrderItemModalOpened}
          onCancel={() => {
            setselectedRowKey(null);
            setSelectedMenuItem(null);
            setSelectedVariant(null);
            setMenuItemCount(null);
            setOrderItemMode("");
            setSearchMenuItemVal("");
            setIsDeleteOrderItemModalOpened(false);
          }}
          onOk={() => {
            const isdeletedItemLocale = tableData?.find(
              (el) => el?.key === selectedRowKey
            )?.locale;
            if (isdeletedItemLocale) {
              setTableData((prev) => prev?.filter((el) => !el?.locale));
            } else {
              deleteOrderItem({
                order_items: order?.order_items?.filter(
                  (el) => el?.id !== selectedRowKey
                ),
                id: order?.id,
              });
            }
            setIsDeleteOrderItemModalOpened(false);
          }}
        />
        <AddBlackList
          title={
            information?.customer?.blacklist && information?.customer?.blacklist_reason
              ? "Edit Black List Patient"
              : "Add To Black List"
          }
          isOpened={isBlackListModalOpened}
          onCancel={() => {
            setIsBlackListModalOpened(false);
          }}
          onOk={onBlackListModalOk}
          patient={
            information?.customer?.blacklist && information?.customer?.blacklist_reason
              ? order?.customer
              : null
          }
        />
        <ConfirmModal
          title="Are you sure ?"
          subTitle="You want to remove the patient from black list ?"
          isOpened={isConfirmOpened}
          onCancel={() => {
            setIsConfirmOpened(false);
          }}
          onOk={() => {
            removeFromBL({
              id: order?.customer?.id,
              blacklist: false,
            });
          }}
          icon={
            <ExclamationCircleFilled
              style={{
                color: token?.colorWarning,
                fontSize: "24px",
              }}
            />
          }
        />
      </Drawer>
    </>
  );
}
