import React, { useState } from "react";
import "./DeleteOrder.scss";
import { Typography, Modal, Input, Form, Button, Radio } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import useConfirmMenuModal from "../../hooks/useConfirmMenuModal";
const { TextArea } = Input;

export default function DeleteOrder({
  title,
  isOpened,
  subTitle,
  onOk,
  onCancel,
  afterResponse,
  loading,
}) {
  const { modal, button, text, token } = useConfirmMenuModal();
  const [value, setValue] = useState("");
  const [textAreaValue, setTextAreaValue] = useState("")
  const onChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <Modal
      centered
      closeIcon={false}
      className="deleteorder-cont"
      onOk={() => {
        if(!value) return;
        onOk(value === 4 ? textAreaValue : value);
        if (!afterResponse) {
          onCancel();
        }
      }}
      okText={"Cancel Order"}
      onCancel={onCancel}
      title={
        <div className="deleteorder-cont__titleWrapper">
          <DeleteOutlined
            style={{
              color: `${token?.colorErrorText}`,
            }}
            className="deleteorder-cont__titleWrapper__icon"
          />
          <Typography.Text className="deleteorder-cont__titleWrapper__title">
            {title}
          </Typography.Text>
        </div>


      }
      cancelText="Close"
      cancelButtonProps={{
        size: "small",
        className: "deleteorder-cont__btn",
      }}
      okButtonProps={{
        size: "small",
        type: "primary",
        danger: true,
        loading: loading,
        disabled: value ? false: true,
        className: "deleteorder-cont__btn",
      }}



      open={isOpened}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
    >
      <Typography.Text
        className="deleteorder-cont__subTitle"
        style={{
          color: `${text.titleColor}`,
        }}
      >
        {subTitle}
      </Typography.Text>
      <div>
        <Radio.Group
          className="deleteorder-cont__radiogroup"
          onChange={onChange}
          value={value}
          options={[
            { value: 'No Answer', label: 'No Answer' },
            { value: "Delivery time", label: 'Delivery time' },
            { value: "No product", label: 'No product' },
            { value: "Double order", label: 'Double order' },
            { value: "Scam", label: 'Scam' },
            { value: "Cancelled by shop", label: 'Cancelled by shop' },
            {
              value: 4,
              label: (
                <>
                  Other (free text)
                </>
              ),
            },
          ]}
        />
        {value === 4 && <TextArea
          variant="filled"
          value={textAreaValue}
          onChange={(e) => { setTextAreaValue(e.target.value) }}
          placeholder="Input reason"
          style={{ width: "100%", marginInlineStart: 12 }}
        />}
      </div>
    </Modal>
  );
}
