import React, { useEffect, useState } from "react";
import "./EditSubmenuModal.scss";
import { Typography, Modal, Input, Form, Checkbox } from "antd";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import useMenuModal from "../../hooks/useMenuModal";
import { useParams } from "react-router";
import { resetErrors } from "../../features/menu/menuSlice";
import { get_possible_sub_menus } from "../../features/menu/menuActions";
import Utils from "../../utils";
import { ExclamationCircleFilled, PercentageOutlined } from "@ant-design/icons";

export default function EditSubmenuModal({
  isOpened,
  title,
  isweedMapsMenu,
  //   onOk,
  onCancel,
  targetKey,
  afterResponse,
  subMenu,
  isDisabled,
}) {
  const {
    modal,
    button,
    formRef,
    error,
    loading,
    dispatch,
    editSubMenu,
    formData,
    setFormData,
    isChecked,
    onChackboxChange,
    onMenuCheckboxChange,
    setIsChecked,
    isMenuChecked,
    setIsMenuChecked,
    token,
    foundMenu,
  } = useMenuModal(isOpened, subMenu);

  const { menuId, subMenuId } = useParams();
  const subTitle =
    isweedMapsMenu || isMenuChecked ? ["Name", "External id"] : ["Name"];
  const [isConfirmOpened, setIsConfirmOpened] = useState(false);
  const edit = (
    menuId,
    id,
    text,
    external_id,
    isMetrc_integrated,
    is_connected,
    sale
  ) => {
    return dispatch(
      editSubMenu({
        menuId,
        id,
        text,
        ...(subMenu?.external_id === external_id ? {} : { external_id }),
        sync_with_metrc: isMetrc_integrated,
        is_connected,
        sale,
      })
    );
  };

  const onOk = async (
    menuId,
    id,
    text,
    external_id,
    isMetrc_integrated,
    is_connected,
    sale
  ) => {
    const data = await edit(
      menuId,
      id,
      text,
      external_id,
      isMetrc_integrated,
      is_connected,
      sale
    );

    if (data.type === "editsubmenu/fulfilled") {
      onCancel();
      dispatch(get_possible_sub_menus());
      formRef?.current?.resetFields();
      setIsChecked(false);
      setIsMenuChecked(false);
      setIsConfirmOpened(false);
    }
  };

  useEffect(() => {
    if (subMenu) {
      setFormData([
        {
          name: "name",
          value: subMenu?.name,
        },
        {
          name: "external_id",
          value: subMenu?.external_id,
        },
        {
          name: "sale",
          value: subMenu?.sale?.amount ? subMenu?.sale?.amount : "",
        },
      ]);
      setIsChecked(subMenu?.sync_with_metrc);
      setIsMenuChecked(subMenu?.is_connected);
    }
  }, [isOpened]);

  useEffect(() => {
    if (error && !loading) {
      for (let key in error) {
        formRef?.current?.setFields([
          {
            name: key,
            errors: [error[key][0]],
          },
        ]);
      }
    }
  }, [loading, error, isOpened]);

  return (
    <Modal
      open={isOpened}
      title={title}
      onOk={() => {
        formRef.current.submit();
      }}
      okText="Save"
      closeIcon={false}
      centered
      destroyOnClose={true}
      onCancel={() => {
        onCancel();
        dispatch(resetErrors());
        formRef?.current?.resetFields();
        setIsChecked(false);
        setIsMenuChecked(false);
        setIsConfirmOpened(false);
      }}
      style={{
        borderTop: `2px solid ${button?.colorPrimary}`,
      }}
      className="editSubmenuModal-cont"
      cancelButtonProps={{
        className: "editSubmenuModal-cont__btn",
        size: "small",
      }}
      okButtonProps={{
        size: "small",
        htmlType: "submit",
        type: "primary",
        className: "editSubmenuModal-cont__btn",
      }}>
      <Form
        layout="vertical"
        ref={formRef}
        className="editSubmenuModal-cont__form"
        onFinish={(values) => {
          if (isDisabled) {
            setIsConfirmOpened(true);
          } else {
            Number(values["sale"])
              ? onOk(
                  menuId,
                  subMenuId,
                  values["name"],
                  values["external_id"],
                  isChecked,
                  isMenuChecked,
                  {
                    amount: Number(values["sale"]),
                  }
                )
              : onOk(
                  menuId,
                  subMenuId,
                  values["name"],
                  values["external_id"],
                  isChecked,
                  isMenuChecked
                );
          }
        }}
        onFinishFailed={(error) => {}}
        fields={formData}>
        <Form.Item
          name="name"
          className="editSubmenuModal-cont__form__formItem"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="editSubmenuModal-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}>
              {subTitle[0]}
            </Typography.Text>
          }>
          <Input type="text" placeholder="Name" disabled={isDisabled} />
        </Form.Item>
        {subTitle[1] ? (
          <Form.Item
            name="external_id"
            className="editSubmenuModal-cont__form__formItem"
            rules={[
              {
                required: true,
                message: "External id is required",
              },
            ]}
            colon={false}
            label={
              <Typography.Text
                className="editSubmenuModal-cont__form__formItem__text"
                style={{
                  color: `${modal.titleColor}`,
                }}>
                {subTitle[1]}
              </Typography.Text>
            }>
            <Input type="text" disabled={isDisabled} />
          </Form.Item>
        ) : null}
        <Form.Item
          name="sale"
          className="menuModal-cont__form__formItem"
          rules={[
            {
              validator: Utils?.saleValidator,
            },
          ]}
          colon={false}
          label={
            <Typography.Text
              className="menuModal-cont__form__formItem__text"
              style={{
                color: `${modal.titleColor}`,
              }}>
              Submenu Sale
            </Typography.Text>
          }>
          <Input
            type="text"
            prefix={<PercentageOutlined />}
            onChange={() => {
              if (error && error?.sale) {
                formRef?.current?.setFields([{ name: "sale", errors: [] }]);
              }
            }}
          />
        </Form.Item>
        <Checkbox
          onChange={onChackboxChange}
          checked={isChecked}
          disabled={isDisabled}>
          METRC Integrated
        </Checkbox>
        {foundMenu?.name === "canasale" || foundMenu?.name === "shop" || (
          <Checkbox
            onChange={onMenuCheckboxChange}
            checked={isMenuChecked}
            disabled={isDisabled}>
            Connected With{" "}
            {foundMenu?.name ? Utils?.menuNameMapping(foundMenu?.name) : ""}
          </Checkbox>
        )}
      </Form>
      {isDisabled ? (
        <ConfirmModal
          isOpened={isConfirmOpened && isOpened}
          title={"Are you sure"}
          subTitle={
            "You want to change submenu sale amount ? After changing the amount, it will be applied to all items in the submenu."
          }
          icon={
            <ExclamationCircleFilled
              style={{
                color: token?.colorWarning,
                fontSize: "24px",
              }}
            />
          }
          onCancel={() => {
            setIsConfirmOpened(false);
          }}
          onOk={() => {
            setIsConfirmOpened(false);
            const values = formRef?.current?.getFieldsValue();
            onOk(
              menuId,
              subMenuId,
              values["name"],
              values["external_id"],
              isChecked,
              isMenuChecked,
              {
                amount: Number(values["sale"]),
              }
            );
          }}
        />
      ) : null}
    </Modal>
  );
}
