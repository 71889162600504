import { theme } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategorys } from "../features/category/categoryActions";
import { getLocations } from "../features/location/locationAction";
import { resetGenericReportError } from "../features/report/genericReports/genericReportsSlice";
import dayjs from "dayjs";
import useWindowWidth from "./useWindowWidth";
import Utils from "../utils";

export default function useCreateReportModal(isOpened, onCancel, report) {
  const { useToken } = theme;
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - 2010 + 1 },
    (_, index) => 2010 + index
  );
  const { isMobile } = useWindowWidth();
  const { token } = useToken();
  const drivers = useSelector((state) => state?.driver?.drivers?.results);
  const {
    loading: categoryLoading,
    categorys: { meta: catgoryMeta, results: categorys },
  } = useSelector((state) => state?.categorys);
  const { loading, error, success } = useSelector(
    (state) => state?.genericReports
  );
  const { possibleLocations } = useSelector((state) => state?.locations);

  const formRef = useRef();
  const dispatch = useDispatch();
  const [isExactDateChecked, setIsExactChecked] = useState(false);
  const [isYearChecked, setIsYearChecked] = useState(true);
  const [isMonthChecked, setisMonthChecked] = useState(true);
  const [selectedYear, setSelectedYear] = useState(null);
  const [formData, setFormData] = useState([]);
  const [isByMetrc, setIsByMetrc] = useState(false);

  useEffect(() => {
    if (isOpened) {
      if (report) {
        const startDate = new Date(
          Utils?.getFormattedDateTime(report?.start_date)
        );
        const startMonthIndex = startDate.getMonth();
        const startYear = startDate?.getFullYear();
        setFormData([
          {
            name: "name",
            value: report?.name ? report?.name : "",
          },
          {
            name: "categories",
            value: report?.categories?.length
              ? report?.categories?.map((cat) => (cat?.id ? cat?.id : cat))
              : [],
          },
          {
            name: "locations",
            value: report?.locations?.length
              ? report?.locations?.map((loc) => (loc?.id ? loc?.id : loc))
              : [],
          },
          {
            name: "drivers",
            value: report?.drivers?.length ? report?.drivers : [],
          },
          {
            name: "year",
            value: report?.start_date ? startYear : null,
          },
          {
            name: "month",
            value: report?.start_date ? months[startMonthIndex] : null,
          },
          {
            name: "exact_date",
            value:
              report?.start_date || report?.end_date
                ? report?.start_date && report?.end_date
                  ? [dayjs(report?.start_date), dayjs(report?.end_date)]
                  : report?.start_date
                  ? [dayjs(report?.start_date)]
                  : [, dayjs(report?.end_date)]
                : [],
          },
          {
            name: "report_type",
            value: report?.report_type ? report?.report_type : null,
          },
        ]);
        if (report?.metrc) {
          setIsByMetrc(true);
        }
      }
    }
  }, [isOpened]);

  useEffect(() => {
    if (isOpened) {
      dispatch(getCategorys({ parent__isnull: true, per_page: 100000 }));
      dispatch(getLocations());
    }
  }, [isOpened, dispatch]);

  useEffect(() => {
    if (isOpened) {
      if (success) {
        onCancel();
        if (error) {
          dispatch(resetGenericReportError());
        }
        formRef?.current?.resetFields();
        setSelectedYear(null);
        setIsByMetrc(false);
      }
    }
  }, [success]);

  useEffect(() => {
    if (isOpened) {
      if (error && !loading) {
        for (let key in error) {
          if (key === "start_date" || key === "end_date") {
            if (isExactDateChecked) {
              formRef?.current?.setFields([
                {
                  name: "exact_date", // Field name you want to set the error for
                  errors: [error[key][0]], // Assuming your error has a 'message' field
                },
              ]);
            } else if (isYearChecked && isMonthChecked) {
              formRef?.current?.setFields([
                {
                  name: "year", // Field name you want to set the error for
                  errors: [error[key][0]], // Assuming your error has a 'message' field
                },
              ]);
            } else if (isYearChecked) {
              formRef?.current?.setFields([
                {
                  name: "year", // Field name you want to set the error for
                  errors: [error[key][0]], // Assuming your error has a 'message' field
                },
              ]);
            } else if (isMonthChecked) {
              formRef?.current?.setFields([
                {
                  name: "month", // Field name you want to set the error for
                  errors: [error[key][0]], // Assuming your error has a 'message' field
                },
              ]);
            }
          }
          formRef?.current?.setFields([
            {
              name: key, // Field name you want to set the error for
              errors: [error[key][0]], // Assuming your error has a 'message' field
            },
          ]);
        }
      }
    }
  }, [error, loading]);

  return {
    token,
    formRef,
    drivers,
    months,
    years,
    categoryLoading,
    categorys,
    isExactDateChecked,
    setIsExactChecked,
    isYearChecked,
    setIsYearChecked,
    isMonthChecked,
    setisMonthChecked,
    dispatch,
    loading,
    error,
    setSelectedYear,
    resetGenericReportError,
    formData,
    isMobile,
    possibleLocations,
    isByMetrc,
    setIsByMetrc,
  };
}
